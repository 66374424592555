const EventEmitter = require('eventemitter3')
// require('glider-js/glider.min.js')
// require('glider-js/glider.min.css')
// Firebase
const { uuidv4 } = require('@firebase/util')
const { getAnalytics, logEvent } = require('firebase/analytics')
const { initializeApp } = require('firebase/app')
const { request, gql } = require('graphql-request')
const Siema = require("siema")
const API_ENDPOINT = 'https://api.sparx.ai/graphql'
//==========================================================================
// FIREBASE CONFIG
//==========================================================================

const firebaseConfig = {
  apiKey: 'AIzaSyDHE7KtV78pSqiW1tUKlgH8V7rXScZx-94',
  authDomain: 'sparx-ios-production-new.firebaseapp.com',
  projectId: 'sparx-ios-production-new',
  storageBucket: 'sparx-ios-production-new.appspot.com',
  messagingSenderId: '358894666255',
  appId: '1:358894666255:web:ee0b93c5f48ac69f2d597a',
  measurementId: 'NKVTZ3S30G',
}

const firebaseApp = initializeApp(firebaseConfig)
const firebaseAnalytics = getAnalytics(firebaseApp)

function GQLRequest(query, params = {}) {
  return request(API_ENDPOINT, query, params, {
    Authorization: 'xZRuBuykeDuRRei0lw0Oe5WJOXbwuRQK',
  })
}

//==========================================================================
// Sort by room_classification weight
//==========================================================================

function sortByWeight(a, b) {
  return b.room_classification.weight - a.room_classification.weight
}

function fetchVideos(unitNumber, cb) {
  getUnitByUnitNumber(unitNumber, (unit, err) => {
    if (!unit && err) {
      console.error(`Failed fetching unit ${unitNumber}`)
      cb(null, true)
      return
    }
    GQLRequest(
      `
      query Videos_by_unit_type_id($unitTypeId: Int) {
        videos_by_unit_type_id(unit_type_id: $unitTypeId) {
          id
          src
          thumbnail
          is_official
          is_default
          is_verified
          name
          timestamp
        }
      }
    `,
      { unitTypeId: unit.units_by_unit_number[0].unit_type.id },
    )
      .then((data) => {
        cb(data)
      })
      .catch((err) => {
        cb(null, err)
      })
  })
}

function getUnitByUnitNumber(unitNumber, cb) {
  GQLRequest(
    `
    query Units_by_unit_number($unitNumber: String!) {
      units_by_unit_number(unit_number: $unitNumber) {
        id
        name
        unit_type {
          id
          unit_type_levels {
            rooms {
              room_classification {
                weight
              }
              id
              room_designs {
                id
                design_name
                photos {
                  is_default
                  id
                  src
                  assets {
                    asset {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
    {
      unitNumber,
    },
  )
    .then((data) => cb(data))
    .catch((err) => cb(null, err))
}

//==========================================================================
// CDN Prefixing function
//==========================================================================

function getCdnAssetUrl(path) {
  const reg = new RegExp('^(?:[a-z+]+:)?//', 'i')
  // Check for absolute URL
  if (reg.test(path)) {
    return path
  } else {
    // Generate absolute CDN url for relative path
    return `https://cdn.sparx.ai/${path}`
  }
}

;(() => {
  const EE = new EventEmitter()

  function getCdnAssetUrl(path) {
    const reg = new RegExp('^(?:[a-z+]+:)?//', 'i')
    // Check for absolute URL
    if (reg.test(path)) {
      return path
    } else {
      // Generate absolute CDN url for relative path
      return `https://cdn.sparx.ai/${path}`
    }
  }

  function embedURL(unitNumber, cb, options = {}) {
    getUnitByUnitNumber(unitNumber, (unit, err) => {
      if (!unit && err) {
        console.error(`Failed fetching unit ${unitNumber}`)
        cb(false)
        return
      }
      const unitId = unit.units_by_unit_number[0].id
      const { page, hideScreens, host, ...other } = options
      const root = host || `https://iframe.sparx.ai`
      const search = {
        unitId,
        unitNumber,
        ...(hideScreens ? { hide_screens: hideScreens.join(',') } : {}),
        ...(other || {}),
      }
      cb(
        `${root}/${page || 'videos'}?${Object.keys(search)
          .map((key) => `${key}=${search[key]}`)
          .join('&')}`,
      )
    })
  }

  function validateUnit(unitNumber, cb) {
    getUnitByUnitNumber(unitNumber, (data, err) => {
      if (data && data.units_by_unit_number && data.units_by_unit_number.length > 0) {
        cb(data.units_by_unit_number)
      } else {
        cb(false)
      }
    })
  }

  function unitPhotos(unitNumber, cb) {
    getUnitByUnitNumber(unitNumber, (data, err) => {
      if (err) {
        console.log({err})
        console.error(`Error occured`)
        cb([])
      }
      if (data && data.units_by_unit_number && data.units_by_unit_number.length > 0) {
        const [unit] = data.units_by_unit_number
        const photos = []
        unit.unit_type.unit_type_levels
          .map((l) => l.rooms)
          .flat()
          .sort(sortByWeight)
          .forEach((room) => {
            room.room_designs.forEach((design) => {
              const photosAvailable = design.photos.filter(({ assets }) => assets.length > 0)
              const photo = photosAvailable.find((photo) => photo.is_default) || photosAvailable[0]
              if (photo) {
                photos.push({
                  src: getCdnAssetUrl(photo.src),
                  room_design_id: design.id,
                  room_weight: room.room_classification.weight,
                  is_default: photo.is_default,
                })
              }
            })
          })
        cb(photos)
      } else {
        console.error(`Unit not found or mapping failed`)
        cb([])
      }
    })
  }

  function stopVideo(unitNumber, reset = false) {
    const el = document.querySelector(`[data-unit-scode="${unitNumber}"]`)
    if (el) {
      const video = el.getElementsByTagName('video')[0]
      if (video.currentTime > 0) {
        video.pause()
        if (reset) {
          video.currentTime = 0
        }
      }
    }
  }

  function stopAllVideos(reset = false) {
    const els = document.querySelectorAll('[data-sparx-video]')
    if (els) {
      els.forEach((el) => {
        const video = el.getElementsByTagName('video')[0]
        if (video.currentTime > 0) {
          video.pause()
          if (reset) {
            video.currentTime = 0
          }
        }
      })
    }
  }

  function generateVideoPlayerMarkup(video, index) {
    return `
    <div class="controls">
      <!-- Play -->
      <svg
        width="27"
        height="31"
        viewBox="0 0 27 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="play_button"
      >
        <path
          d="M2.98905 0.700667C1.65576 -0.057931 0 0.904997 0 2.43899V28.561C0 30.095 1.65575 31.0579 2.98905 30.2993L25.9448 17.2383C27.2927 16.4714 27.2927 14.5286 25.9448 13.7617L2.98905 0.700667Z"
          fill="white"
        />
      </svg>
      <!-- Pause -->
      <svg
        width="37"
        height="43"
        viewBox="0 0 37 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="pause_button hidden"
      >
        <path
          d="M9.03578 0.833008H3.01193C1.34849 0.833008 0 2.18149 0 3.84494V39.9881C0 41.6515 1.34849 43 3.01193 43H9.03578C10.6992 43 12.0477 41.6515 12.0477 39.9881V3.84494C12.0477 2.18149 10.6992 0.833008 9.03578 0.833008Z"
          fill="white"
        />
        <path
          d="M33.1308 0.833008H27.1069C25.4435 0.833008 24.095 2.18149 24.095 3.84494V39.9881C24.095 41.6515 25.4435 43 27.1069 43H33.1308C34.7942 43 36.1427 41.6515 36.1427 39.9881V3.84494C36.1427 2.18149 34.7942 0.833008 33.1308 0.833008Z"
          fill="white"
        />
      </svg>
    </div>
    <button type="button" class="tagButton">VIEW GALLERY & FLOOR PLAN</button>
    <video controlsList="nodownload" id="video_${index}" poster="${getCdnAssetUrl(video.thumbnail)}">
      <source src="${getCdnAssetUrl(video.src)}" type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
  `
  }

  // ==========================================================================
  // Styles
  // ==========================================================================
  const style = document.createElement('style')

  style.textContent = `
  .hidden {
    display:none
  }
  .sparx-video-wrap{
    background-color: black;
    aspect-ratio: 523/294;
    display: flex;
    align-items: center;
    overflow: hidden;
    position:relative;
  }

  .sparx-video-wrap video{
    width:100%;
    object-fit: cover;
  }
  .controls {
    height:48px;
    width:48px;
    position: absolute;
    top: 50%; right: 50%;
    transform: translate(50%,-50%);
    display:flex;
    align-items:center;
    justify-content: center;
  }
  .hidden {
    display: none;
  }
  .placeholder{
    background: #F5F5F8;
    aspect-ratio: 16 / 9;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  .tagButton{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background: #892A86;
    color: white;
    border: none;
    cursor:pointer;
    padding: 10px;
  }
  .tagButton:hover{
    background: #5E1D5C
  }

  .sparx-slider-wrap .dots-wrap {
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: 10px;
      position: absolute;
      bottom: 20px;
      left: 0;
      z-index: 10;
    }


  .sparx-slider-wrap .dots {
      display: flex;
      justify-content: center;
      background: rgba(0,0,0,.3);
      padding: 5px 10px;
      align-items: center;
      border-radius: 10px;
      height:20px;
    }

    .sparx-slider-wrap .dots button {
    transition: all ease-in .1s;
      width: 6px;
      height: 6px;
      background-color: #fff;
      border: none;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
    }

    .sparx-slider-wrap .dots button.active {
       width: 10px;
      height: 10px;
    }

    .sparx-slider-wrap {
      background: rgb(55, 55, 55);
      position: relative;
    }

    .sparx-slider-wrap.centered{
      align-items: center;
      display: flex;
    }

    .sparx-slider-wrap .sparx-photo-slider > div {
      display: flex;
      align-items: center;
    }
`

  document.head.appendChild(style)

  const unitMetadata = {}

  // ==========================================================================
  // Render videos
  // ==========================================================================

  function renderVideos() {
    const els = document.querySelectorAll('[data-sparx-video]')

    els.forEach((el, index) => {
      el.innerHTML = `<div class="placeholder">
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.4999 1H5.49998C3.01471 1 1 3.01472 1 5.50001V20.5C1 22.9853 3.01471 25.0001 5.49998 25.0001H20.4999C22.9852 25.0001 24.9999 22.9853 24.9999 20.5V5.50001C24.9999 3.01472 22.9852 1 20.4999 1Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1 17.497L6.99998 11.497C7.68406 10.8387 8.46006 10.4922 9.24997 10.4922C10.0399 10.4922 10.8159 10.8387 11.5 11.497L18.9999 18.997" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 15.997L17.5 14.497C18.1841 13.8387 18.9601 13.4922 19.75 13.4922C20.5399 13.4922 21.3159 13.8387 22 14.497L25 17.497" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="19.2391" cy="6.99687" r="1.2" fill="black"/>
</svg>
      </div>`
      const unitId = el.getAttribute('data-unit-scode')
      if (!unitId) {
        console.warn(`Node ${index} data-unit-scode is not specified or empty`)
        return
      }
      console.log(`Rendering ${unitId}`)
      getUnitByUnitNumber(unitId, (data, err) => {
        let unit
        if (data) {
          unit = data.units_by_unit_number[0]
          unitMetadata[unitId] = unit
        }
        fetchVideos(unitId, (data, err) => {
          if (!data && err) {
            console.error(`Failed rendering video content for unit ${unitId}, item will be removed from DOM`)
            el.parentNode.remove()
            return
          }
          let videoPlayedOver50PercentEventSent = false
          const video = data.videos_by_unit_type_id.find((video) => video.is_default)
          if (!video) {
            el.parentNode.remove()
            console.warn(`Video fetching error for unit id ${unitId}, item will be removed from DOM`)
            return
          }
          unitMetadata[unitId] = {
            ...unitMetadata[unitId],
            video,
          }
          const videoPayload = {
            ...video,
            unit_id: unitId,
          }
          const html = generateVideoPlayerMarkup(video, index)
          el.innerHTML = html
          el.classList.add('sparx-video-wrap')
          const videoEl = document.getElementById(`video_${index}`)
          videoEl.onplay = () => {
            videoPlayedOver50PercentEventSent = false
            analyticsLogDefault('listing_page_video_start', {
              ...videoPayload,
              ...getUnitEventExtraData(unitId),
            })
          }
          videoEl.onended = () => {
            videoPlayedOver50PercentEventSent = false
            analyticsLogDefault('listing_page_video_end', {
              ...videoPayload,
              ...getUnitEventExtraData(unitId),
            })
            videoEl.currentTime = 0
          }
          videoEl.ontimeupdate = (v) => {
            const { duration, currentTime } = v.currentTarget
            const currentlyPlayedPercentage = Math.round((currentTime * 100) / duration)
            if (currentlyPlayedPercentage > 50 && !videoPlayedOver50PercentEventSent) {
              analyticsLogDefault('listing_page_video_over_50_percent', {
                ...videoPayload,
                ...getUnitEventExtraData(unitId),
              })
              videoPlayedOver50PercentEventSent = true
            }
          }
          analyticsLogDefault('listing_page_sparx_unit_view', {
            partnerUnitId: unitId,
            ...getUnitEventExtraData(unitId),
            ...(el.getAttribute('data-partner-property-id')
              ? { partnerPropertyId: el.getAttribute('data-partner-property-id') }
              : {}),
          })

          const playButton = el.getElementsByClassName('play_button')[0]
          const pauseButton = el.getElementsByClassName('pause_button')[0]

          el.onclick = function () {
            if (!videoEl.controls) {
              videoEl.play()

              el.getElementsByClassName('controls')[0].onclick = function () {
                if (videoEl.paused) {
                  videoEl.play()
                } else {
                  videoEl.pause()
                }
              }
            }
          }

          //==========================================================================
          // Act on video paused
          //==========================================================================
          videoEl.addEventListener('pause', function () {
            if (this.seeking) {
              return
            }
            if (!pauseButton.classList.contains('hidden')) {
              pauseButton.classList.add('hidden')
            }
            playButton.classList.toggle('hidden')
          })

          //==========================================================================
          // Act on video played/resumed
          //==========================================================================
          videoEl.addEventListener('play', function () {
            if (!videoEl.controls) {
              videoEl.controls = true
            }
            if (this.seeking) {
              return
            }
            playButton.classList.add('hidden')
            pauseButton.classList.remove('hidden')
            setTimeout(() => {
              if (!pauseButton.classList.contains('hidden')) {
                pauseButton.classList.add('hidden')
              }
            }, 2000)
          })

          el.getElementsByClassName('tagButton')[0].onclick = (e) => {
            e.stopPropagation()
            analyticsLogDefault('listing_page_tag_apartment_page_click', {
              partnerUnitId: unitId,
              ...getUnitEventExtraData(unitId),
              ...(el.getAttribute('data-partner-property-id')
                ? { partnerPropertyId: el.getAttribute('data-partner-property-id') }
                : {}),
            })

            embedURL(unitId, (data) => {
              EE.emit(`sparx.apartment-unit-tag-click`, data)
            })
          }
        })
      })
    })
  }

  function renderSliders() {
    const sliders = document.querySelectorAll(`[data-sparx-slider]`)
    console.log(sliders)
    for (const slider of Array.from(sliders)) {
      const unit = slider.getAttribute('data-unit')

      unitPhotos(unit, (photos) => {
        
        const className = `${unit.toLowerCase()}_slider`
        slider.classList.add('sparx-slider-wrap')
        slider.innerHTML = ` <div class="dots-wrap"><div id="${className}" class="dots"></div></div><div class="sparx-photo-slider ${className}">
        ${photos.map((p) => `<div><img src="${p.src}"></div>`).join('')}<div><img src="${photos[0].src}"></div>
      </div>`


     

      const siema = new Siema({
          selector: `.${className}`,
          loop: true, // Enable infinite scrolling
          onInit: ()=>{
            slider.classList.add('centered')
          },   // Call updateDots when Siema initializes
          onChange: updateDots  // Call updateDots when the slide changes
        })

        // Set up dots (navigation)
        const dots = slider.querySelector('.dots')
        for (let i = 0; i < siema.innerElements.length; i++) {
          const dot = document.createElement('button')
          dot.addEventListener('click', () => siema.goTo(i))
          dots.appendChild(dot)
        }

        function updateDots() {
          const allDots = slider.querySelectorAll('.dots button');
          allDots.forEach(dot => dot.classList.remove('active'));
          allDots[siema.currentSlide].classList.add('active');
        }
      
        // Initial dot highlighting
        updateDots();
      })
    }
  }

  renderVideos()
  renderSliders()

  //==========================================================================
  // Analytics
  //==========================================================================

  function analyticsLogDefault(event, data = {}) {
    const time = new Date()

    const payload = {
      ...data,
      product: 'ST',
      timestamp: time.toISOString(),
    }
    if (localStorage.getItem('sparx.debug')) {
      console.log(`@SPARX event --> event: ${event}`, payload)
    }
    logEvent(firebaseAnalytics, event, payload)
  }

  function analyticsProxyIframe(event, data = {}) {
    if (localStorage.getItem('sparx.debug')) {
      console.log(`@SPARX proxied event --> event: ${event}`, data)
    }
    logEvent(firebaseAnalytics, event, data)
  }

  function getUnitEventExtraData(unitNumber) {
    const unit = unitMetadata[unitNumber]
    if (!unit) {
      return {}
    }
    return {
      unit_type_id: unit.unit_type.id,
      unit_id: unit.id,
      unit_number: unitNumber,
      video_id: unit.video.id,
    }
  }

  const analytics = {
    onListingPageOpenApartmentPageClick: (partnerUnitId, partnerPropertyId) => {
      analyticsLogDefault('listing_page_open_apartment_page_click', {
        ...getUnitEventExtraData(partnerUnitId),
        partnerUnitId,
        partnerPropertyId,
      })
    },
    onListingPageBookTourClick: (partnerUnitId, partnerPropertyId) => {
      analyticsLogDefault('listing_page_book_tour_click', {
        ...getUnitEventExtraData(partnerUnitId),
        partnerUnitId,
        partnerPropertyId,
      })
    },
    onListingPageCallClick: (partnerUnitId, partnerPropertyId) => {
      analyticsLogDefault('listing_page_call_click', {
        ...getUnitEventExtraData(partnerUnitId),
        partnerUnitId,
        partnerPropertyId,
      })
    },
    onApartmentPageClose: (partnerUnitId, partnerPropertyId) => {
      analyticsLogDefault('apartment_page_close', {
        ...getUnitEventExtraData(partnerUnitId),
        partnerUnitId,
        partnerPropertyId,
      })
    },
  }

  // ==========================================================================
  // Prepare SDK
  // ==========================================================================

  const sparxSDK = {
    events: EE,
    api: {
      embedURL,
      validateUnit,
      analytics,
      stopVideo,
      stopAllVideos,
      unitPhotos,
    },
  }

  if (window) {
    window.sparxSDK = sparxSDK
    window.addEventListener(
      'message',
      ({ origin, data }) => {
        if (data && data.message && data.message.event) {
          if (data.message.event.includes('analytics')) {
            analyticsProxyIframe(data.message.event.split('.')[1], data.message.data)
            return
          }
          EE.emit(`sparx.${data.message.event}`, data.message.data || null)
        }
      },
      false,
    )
  }

  // ==========================================================================
  // Raise ready event
  // ==========================================================================
  const event = new Event('sparx.ready')
  document.dispatchEvent(event)
})()
